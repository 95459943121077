window.addEventListener('load', function () {
  const toggleSearch = document.querySelector('.toggleSearch');
  const searchBar = document.querySelector('#search-modal');
  const closeSearch = document.querySelector('#search-close');
  const dropdowns = document.querySelectorAll('.nav-primary .dropdown');

  if (toggleSearch) {
    toggleSearch.addEventListener('click', function () {
      if (searchBar.classList.contains('opened')) {
        searchBar.classList.remove('opened');
        toggleSearch.setAttribute('aria-expanded', 'false');
      } else {
        searchBar.classList.add('opened');
        toggleSearch.setAttribute('aria-expanded', 'true');
        document.getElementById('search-field-ts-control').focus();
      }
    });
  }

  if (closeSearch) {
    closeSearch.addEventListener('click', function () {
      if (searchBar.classList.contains('opened')) {
        searchBar.classList.remove('opened');
        toggleSearch.setAttribute('aria-expanded', 'false');
        closeSearch.setAttribute('aria-expanded', 'false');
      }
    });
  }

  if (0 < dropdowns.length) {
    dropdowns.forEach(function (dropdown) {
      dropdown.addEventListener('mouseover', function () {
        if (searchBar.classList.contains('opened')) {
          searchBar.classList.remove('opened');
          toggleSearch.setAttribute('aria-expanded', 'false');
          closeSearch.setAttribute('aria-expanded', 'false');
        }
      });
    });
  }
});
